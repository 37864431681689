import React, { Component } from 'react';
import SEO from "../components/seo"
import styles from '../styles/scss/pages/userStories.module.scss';
import PageContents from '../components/pageContents'
import Helmet from "react-helmet"
import NewLayout from '../layout/newLayout';

export default class Index extends Component {
    render() {
        return (
            <NewLayout title={this.props.pageContext.page.title} newLayout>
                <Helmet>
                    <script src="https://assets.juicer.io/embed-no-jquery.js" type="text/javascript" defer></script>
                    <link rel="stylesheet" href="//assets.juicer.io/embed.css" media="all" type="text/css" />
                </Helmet>
                {this.props.pageContext.page.seo.title && <h1 style={{ display: 'none' }}>{this.props.pageContext.page.seo.title}</h1>}
                <SEO page={this.props.pageContext.page} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={styles.container}>

                        <PageContents content={this.props.pageContext.page.content} />

                        <div className={styles.items}>
                            <ul className="juicer-feed" data-feed-id="wd-40-company-feeds"></ul>
                        </div>
                    </div>
                </div>
            </NewLayout>
        );
    }
}
